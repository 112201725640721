<template>
  <div>
    <ch-layout page search>
      <template v-slot:search>
        <ch-search
            :render-option="searchOption" :model="searchForm"
            :props="{ labelWidth: '66px'}">
        </ch-search>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:handle="scope">
            <ch-button type="link" @click="couponUsageDetails(scope.row.id)">用券明细</ch-button>
            <ch-button type="link" @click="openDetails(scope.row)">查看</ch-button>
          </template>
        </ch-table>
      </template>
      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <detail ref="detail" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import detail from './detail.vue'

export default {
  components:{
    detail
  },
  data(){
    return{
      searchForm:{
        companyId:'',
        status:null,
        name:null,
        amount:null,
        goodsName:null,
        time: [],
        validStart: '',
        validEnd: '',
      },
      tableList: [], // table列表
      isTableLoading: false, // table加载,
      companyList: [], // 商家列表
      statusList: ['待审核', '活动中', '审核拒绝', '已过期'], // 状态列表 0=待审核，1=审核通过，2=审核拒绝,3=已过期
      auditDialogVisible: false, // 审核弹窗
      auditForm: {
        status: 1,
      },
      isAuditLoading: false, // 审核加载
    }
  },
  computed:{
    searchOption:function (){
      return [
        {type: 'input', label: '抵扣券名称：', prop: 'name', placeholder: '请输入抵扣券名称',labelWidth: '88px'},
        {type: 'number', label: '抵扣券面值：', prop: 'amount', placeholder: '请输入抵扣券面值',labelWidth: '88px'},
        {type: 'time', label: '有效期', prop:'time',dateType: 'daterange',format:"yyyy-MM-dd HH:mm:ss",},
        {type: 'select', label: '状态：', prop: 'status', placeholder: '请选择状态',name: "label", value: "value",option: [
            {label: '活动中', value: 1},
            {label: '已过期', value: 3},
          ]},
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]},
      ]
    },
    tableOption:function (){
      return [
        { column: "type", prop: "", type: "selection", width: "25px" },
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "抵扣券名称", prop: "name", showOverflowTooltip: true,},
        { column: "text", label: "使用门槛", prop: "_threshold", showOverflowTooltip: true,},
        { column: "text", label: "满额使用", prop: "thresholdValue", showOverflowTooltip: true,sign: "元",},
        { column: "text", label: "抵扣券面值", prop: "amount", showOverflowTooltip: true,sign: "元",},
        { column: "text", label: "有效期", prop: "_validityType", showOverflowTooltip: true,},
        { column: "text", label: "是否可叠加用券", prop: "_stackableCoupon", showOverflowTooltip: true,},
        { column: "text", label: "是否可叠加优惠", prop: "_stackableDiscount", showOverflowTooltip: true,},
        { column: "text", label: "状态", prop: "_status", showOverflowTooltip: true,},
        { column: "slot", label: "操作", slotName: "handle", width: "200" ,fixed: "right"},
      ];
    },
    auditOption: function () {
      return [
        {type: 'radio', label: '审核：', prop: 'status', option:[
            {value: 1, label: '通过'},
            {value: 2, label: '不通过'},
          ]},
      ]
    }
  },
  mounted() {
    this.getTableList()
  },
  methods:{
    // 查询
    searchHandle() {
      if(this.searchForm.time && this.searchForm.time.length === 2){
        const [start, end] = this.searchForm.time
        this.searchForm.validStart = start
        this.searchForm.validEnd = end
      }else{
        this.searchForm.validStart = ''
        this.searchForm.validEnd = ''
      }
      this.getTableList()
    },
    // 列表数据
    getTableList() {
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/hmVoucher/list",this.searchForm).then((res) => {
        this.tableList = res.rows.map((item) => {
          return{
            ...item,
            _stackableCoupon: item.stackableCoupon ? "可以叠加" : "不可叠加",
            _stackableDiscount: item.stackableDiscount ? "可以叠加" : "不可叠加",
            _status: this.statusList[item.status],
            _validityType: item.validityType ? item.validDays + "天" : dayjs(item.validStart).format("YYYY-MM-DD") + "一" + dayjs(item.validEnd).format("YYYY-MM-DD"),
            _threshold: item.threshold ? "满额使用" : "无门槛",
          }
        })
      }).finally(() => {this.isTableLoading = false});
    },
    openDetails(row){
      this.$refs.detail.open(JSON.stringify(row))
    },
    couponUsageDetails(id){
      this.$router.push({name:'couponUseDetails',query:{id}})
    }
  }
}
</script>

<style scoped>

</style>